import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

const Box = styled.div`
  padding: 1px 5px;
  display: inline-block;
  background-color: #a185ff;
  font-size: 11px;
  font-style: italic;
  border-radius: 5px;
  color: white;
  line-height: 15px;
  margin-bottom: 4px;
  margin-left: 5px;
`;
const Required = ({ ...props }) => {
  return (
    <Box {...props}>
      <FontAwesomeIcon icon={faAsterisk}></FontAwesomeIcon>
    </Box>
  );
};

export default Required;
