import React from "react";
import styled from "styled-components";

const StyledLabel = styled.label`
  font-size: 15px;
  line-height: 20px;
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: bold;
  @media only screen and (max-width: 1300px) {
    font-size: 15px;
    margin-top: 10px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 17px;
    margin-top: 10px;
  }
`;
const Label = ({ ...props }) => {
  if (props.children || props.dangerouslySetInnerHTML) {
    return (
      <StyledLabel {...props} style={{ display: props.nofloat ? "block" : "" }}>
        {props.children}
      </StyledLabel>
    );
  } else {
    return null;
  }
};

export default Label;
