import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { ifProp } from "styled-tools";

const styles = css`
  font-family: "Roboto", "Open Sans", Arial, Helvetica, sans-serif;
  border: 1px solid rgb(218 220 224);
  border-radius: 8px;
  box-sizing: border-box;
  color: #1f1f1f;
  font-size: 16px;
  height: 40px;
  padding-left: 16px;
  padding-right: 40px;
  display: block;
  width: 100%;
  &:hover {
    border-color: #acaaaa;
  }
  border: ${ifProp("invalid", "3px", "1px")} solid
    ${ifProp("invalid", "red", "#ebebeb")};

  &[type="textarea"] {
    min-height: 85px;
    resize: none;
  }
  &[type="checkbox"],
  &[type="radio"] {
    display: inline-block;
    border: 0;
    border-radius: 0;
    width: auto;
    height: 40px;
    margin: 0 0.2rem 0 0;
  }
  ::placeholder {
    text-transform: none !important;
  }
  :-ms-input-placeholder {
    text-transform: none !important;
  }
`;

const StyledTextarea = styled.textarea`
  ${styles}
`;
const StyledSelect = styled.select`
  ${styles}
`;
const StyledInput = styled.input`
  ${styles}
`;

const Input = ({ ...props }) => {
  if (props.type === "textarea") {
    return <StyledTextarea {...props} />;
  } else if (props.type === "select") {
    return <StyledSelect {...props} />;
  }
  return (
    <StyledInput
      style={{ textTransform: props.upper === true ? "uppercase" : "" }}
      {...props}
    />
  );
};

Input.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  reverse: PropTypes.bool,
  height: PropTypes.number,
  invalid: PropTypes.bool,
};

export default Input;
