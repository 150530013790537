import React from "react";
import LayoutForm from "../../components/layoutForm";
import Seo from "../../components/atoms/seo";
import { graphql } from "gatsby";
import { withTranslation } from "react-i18next";
import AutocompleteInput from "../../components/atoms/addressAutocompleteInput";
import H1 from "../../components/atoms/h1";

class SandBox extends React.Component {
  render() {
    return (
      <LayoutForm headerTitle={"EHS Incident Portal"}>
        <Seo
          title={this.props.t("EHS Incident Portal")}
          keywords={[`PerkinElmer`]}
        />
        <H1>Google Maps Address Lookup Control</H1>
        <AutocompleteInput
          title="Address"
          stackLabel={true}
          name="Location"
          required={true}
        ></AutocompleteInput>
      </LayoutForm>
    );
  }
}
export default withTranslation()(SandBox);
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "incident-report"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
