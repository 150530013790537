import React from "react";
import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import FormField from "./formField";

// Define the libraries array outside the component
const libraries = ["places"];

export default class AutocompleteInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchBox: null,
    };
  }

  onLoad = (searchBox) => {
    this.setState({ searchBox });
  };

  onPlacesChanged = () => {
    const places = this.state.searchBox.getPlaces();
    if (places && places.length > 0) {

      // Handle the place object as needed
    }
  };

  render() {
    const googleMapsApiKey = "AIzaSyDZeR0E2R2GS3QU5mKLj9hz4NbqyO-88OM"; // API Key for testing only
    return (
      <LoadScript googleMapsApiKey={googleMapsApiKey} libraries={libraries}>
        <StandaloneSearchBox
          onLoad={this.onLoad}
          onPlacesChanged={this.onPlacesChanged}
        >
          <FormField
            {...this.props}
            title={this.props.title ? this.props.title : "Address"}
            type="text"
            value={this.props.value}
            name={this.props.name}
            onChange={this.props.onChange}
            placeholder={
              this.props.placeholder
                ? this.props.placeholder
                : "Enter an address"
            }
          />
        </StandaloneSearchBox>
      </LoadScript>
    );
  }
}
